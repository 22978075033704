import React, { useState, useEffect } from "react";

import "./style.css";

import { useFirebase } from "../../utils/FirebaseContext";

const Photography = () => {
  const firebase = useFirebase();
  const [images, setImages] = useState({});

  useEffect(() => {
    firebase
      .getDoc(firebase.doc(firebase.db, "website-data", "images"))
      .then((docSnap) => {
        if (docSnap.exists()) {
          setImages(docSnap.data());
        } else {
          console.log("No such document!");
        }
      });
  }, [firebase]);
  // useEffect(() => {
  //     const init = async () => {
  //         let webpStr
  //         const col1WebpRef = firebase.ref(firebase.storage, '/images/image1/webp');
  //         // const col1JpgRef = firebase.ref(firebase.storage, '/images/image1/jpg');

  //         await firebase.listAll(col1WebpRef)
  //             .then(async res => {
  //                 for (let i = 0; i < res.items.length; i++) {
  //                     const url = await firebase.getDownloadURL(res.items[i])
  //                     const regex = new RegExp(/(_)(\d*w)(\.)(webp|jpg)/)
  //                     const match = url.match(regex)
  //                     webpStr = `${webpStr ? `${webpStr}, ` : ''}${url} ${match[2]}`
  //                 }
  //             })
  //             setImage(webpStr)
  //     }
  //     init()
  // }, [firebase])

  const containerStyle = {
    display: "grid",
    // gridTemplateColumns: "repeat(auto-fit, minmax(220px, 1fr))",
    gridTemplateColumns: "repeat(auto-fit, minmax(440px, 1fr))",
    rowGap: 8,
    columnGap: 8,
    padding: 8,
  };
  const imageStyle = {
    verticalAlign: "middle",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };
  return (
    <div style={containerStyle}>
      {/* <div> */}
      {/* <div> */}
      {images["column-1"] &&
        images["column-1"].map((i, index) => (
          <img
            key={`column-1-photo-${index}`}
            srcSet={i}
            sizes={`calc(60vw / ${window.devicePixelRatio})`}
            alt={`column-1-photography-${index}`}
            style={{ ...imageStyle, maxWidth: 1500 }}
          />
        ))}
      {images["column-2"] &&
        images["column-2"].map((i, index) => (
          <img
            key={`column-2-photo-${index}`}
            srcSet={i}
            sizes={`calc(60vw / ${window.devicePixelRatio})`}
            alt={`column-2-photography-${index}`}
            style={imageStyle}
          />
        ))}
      {images["column-3"] &&
        images["column-3"].map((i, index) => (
          <img
            key={`column-3-photo-${index}`}
            srcSet={i}
            sizes={`calc(60vw / ${window.devicePixelRatio})`}
            alt={`column-3-photography-${index}`}
            style={imageStyle}
          />
        ))}
      {/* </div> */}
      {/* <div className={"column"}>
          {images["column-2"] &&
            images["column-2"].map((i, index) => (
              <img
                key={`column-2-photo-${index}`}
                srcSet={i}
                sizes={`calc(60vw / ${window.devicePixelRatio})`}
                alt={`column-2-photography-${index}`}
                style={imageStyle}
              />
            ))}
        </div>
        <div className={"column"}>
          {images["column-3"] &&
            images["column-3"].map((i, index) => (
              <img
                key={`column-3-photo-${index}`}
                srcSet={i}
                sizes={`calc(60vw / ${window.devicePixelRatio})`}
                alt={`column-3-photography-${index}`}
                style={imageStyle}
              />
            ))}
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default Photography;

import React, { useState, useEffect, useRef } from "react";

import { useFirebase } from "../../../utils/FirebaseContext";

import Video from "../components/Video";

const CreativeVideo = ({ windowSize }) => {
  const containerRef = useRef(null);
  const firebase = useFirebase();
  const [videos, setVideos] = useState({});
  console.log(videos);
  const [headerVideos, setHeaderVideos] = useState([]);
  // console.log(headerVideos);
  useEffect(() => {
    firebase
      .getDoc(firebase.doc(firebase.db, "website-data", "videos"))
      .then((docSnap) => {
        if (docSnap.exists()) {
          console.log(JSON.stringify(docSnap.data()["column-3"]));
          setVideos(docSnap.data());
        } else {
          console.log("No such document!");
        }
      });
  }, []);

  const containerStyle = {
    width: "99vw",
    height: "100%",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
  };

  return (
    <div style={containerStyle} ref={containerRef}>
      {/* <button
        onClick={() => {
          const newcol1 = [
            {
              "thumbnailURL": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FRebel_FormulaOne%2Fthumb%2FFormula%20One%201080%209x16.jpg?alt=media&token=d619f3c5-089d-40e6-b990-8a5b7726f638",
              "sources": [
                {
                  "url": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FRebel_FormulaOne%2FFormula%20One%201080%209x16.mp4?alt=media&token=9397bcaf-81a6-4190-9cab-789c4d392119",
                  "type": "video/mp4"
                }
              ]
            },
            {
              "sources": [
                {
                  "type": "video/mp4",
                  "url": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FSeafolly_Creative_1%2FSeafolly_Creative_1.mp4?alt=media&token=c76302b7-1472-4dcb-9fe1-ea8e1e6ac103"
                }
              ],
              "thumbnailURL": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FSeafolly_Creative_1%2Fthumb%2FSeafolly_Creative_1.jpg?alt=media&token=98cf751e-0692-4d5e-bc89-25dae761c9a9"
            },
            {
              "thumbnailURL": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FDockAndBay_2%2Fthumb%2FDockAndBay_2.jpg?alt=media&token=d57a11e1-c9e8-4fbb-a5a2-3397044227df",
              "sources": [
                {
                  "url": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FDockAndBay_2%2FDockAndBay_2.mp4?alt=media&token=a31ae2f3-ebe0-4533-91ef-74970b6d75a7",
                  "type": "video/mp4"
                }
              ]
            },
            {
              "sources": [
                {
                  "url": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FPetaAndJain_1%2FPetaAndJain_1.mp4?alt=media&token=5f50430b-16ba-4011-832e-23e9c83fa480",
                  "type": "video/mp4"
                }
              ],
              "thumbnailURL": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FPetaAndJain_1%2Fthumb%2FPetaAndJain_1.jpg?alt=media&token=d7354a4d-eeda-4e3a-81f7-f146053040c9"
            }
          ]
          
          const newcol2 = [
            {
              "thumbnailURL": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FRebel_Job1_Story2%2Fthumb%2FStory%202%2016x9.jpg?alt=media&token=44097f5f-b525-43cd-b7c3-47117b7063d0",
              "sources": [
                {
                  "url": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FRebel_Job1_Story2%2FStory%202%2016x9.mp4?alt=media&token=eaf330a8-5fb9-46e7-ad1b-ff91b3390b8c",
                  "type": "video/mp4"
                }
              ]
            },
            {
              "thumbnailURL": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FNimble_Campaign_1%2Fthumb%2FNimble_Campaign_1.jpg?alt=media&token=e8160172-da2b-4a18-b7da-7eaacac02b28",
              "sources": [
                {
                  "type": "video/mp4",
                  "url": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FNimble_Campaign_1%2FNimble_Campaign_1.mp4?alt=media&token=8583909d-ac66-44d6-9836-383689e98e29"
                }
              ]
            },
            {
              "thumbnailURL": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FMarrowbone_Hero_16x9%2Fthumb%2FMarrowbone_Hero_16x9.jpg?alt=media&token=5fdfe990-cf17-494c-9a74-027601e2d84e",
              "sources": [
                {
                  "url": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FMarrowbone_Hero_16x9%2FMarrowbone_Hero_16x9.mp4?alt=media&token=84381c16-7ba1-471f-97d0-76f91dd7979b",
                  "type": "video/mp4"
                }
              ]
            },
            {
              "sources": [
                {
                  "type": "video/mp4",
                  "url": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FDockAndBay_3%2FDockAndBay_3.mp4?alt=media&token=bb987346-1f46-4b95-9d91-1642730a4fe7"
                }
              ],
              "thumbnailURL": "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FDockAndBay_3%2Fthumb%2FDockAndBay_3.jpg?alt=media&token=7c9f0fda-ded2-403e-9159-9248a3c1fb9f"
            }
          ]
          
          const newcol3 = [
            {
              sources: [
                {
                  type: "video/mp4",
                  url: "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FDockAndBay_1%2FDockAndBay_1.mp4?alt=media&token=f5e53b6c-577e-4b78-a24c-00c57ac50342",
                },
              ],
              thumbnailURL:
                "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FDockAndBay_1%2Fthumb%2FDockAndBay_1.jpg?alt=media&token=77b1ec0c-02f3-4ef6-b231-de3e6f5c7c0b",
            },
            {
              sources: [
                {
                  type: "video/mp4",
                  url: "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FRebel_Job1_Story1%2FStory%201%2016x9.mp4?alt=media&token=71546fe6-b923-47e5-9f22-35fef96d3a7a",
                },
              ],
              thumbnailURL:
                "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FRebel_Job1_Story1%2Fthumb%2FStory%201%2016x9.jpg?alt=media&token=c55f4c54-cdf5-43df-8eb0-10551b8a3780",
            },
            {
              thumbnailURL:
                "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FNimble_Campaign_2%2Fthumb%2FNimble_Campaign_2.jpg?alt=media&token=6184998f-78a8-466d-88bf-f77c816972ff",
              sources: [
                {
                  type: "video/mp4",
                  url: "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FNimble_Campaign_2%2FNimble_Campaign_2.mp4?alt=media&token=ab3802f7-37d7-4224-92ec-fb129196a9b1",
                },
              ],
            },
            {
              thumbnailURL:
                "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FSeafolly_Creative_2%2Fthumb%2FSeafolly_Creative_2.jpg?alt=media&token=c417d692-31f2-49eb-8854-45cac8126aa6",
              sources: [
                {
                  url: "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FSeafolly_Creative_2%2FSeafolly_Creative_2.mp4?alt=media&token=30b9e2ef-225c-40d0-a364-b5729528e836",
                  type: "video/mp4",
                },
              ],
            },
          ];

          firebase
            .getDoc(firebase.doc(firebase.db, "website-data", "videos"))
            .then((docSnap) => {
              if (docSnap.exists()) {
                const data = docSnap.data();
                data["column-1"] = newcol1;
                data["column-2"] = newcol2;
                data["column-3"] = newcol3;

                firebase.setDoc(
                  firebase.doc(firebase.db, "website-data", "videos"),
                  data
                );
                console.log(data);
              } else {
                console.log("No such document!");
              }
            });
        }}
      >
        push
      </button> */}
      {/* <div style={headerStyle}>
        <video
          //   ref={vidRef}
          //   poster={headerVideos[0].thumbnailURL}
          //   className="hoverVid"
          style={headerVideoStyle}
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
          preload={"auto"}
        >
          {headerVideos[0]?.sources.map((source, index) => (
            <source
              key={`${source.url}-${index}`}
              type={source.type}
              src={source.url}
            />
          ))}
        </video>
        <video
          //   ref={vidRef}
          //   poster={headerVideos[0].thumbnailURL}
          //   className="hoverVid"
          style={headerVideoStyle}
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
          preload={"auto"}
        >
          {headerVideos[1]?.sources.map((source, index) => (
            <source
              key={`${source.url}-${index}`}
              type={source.type}
              src={source.url}
            />
          ))}
        </video>
      </div> */}
      <div className={"row"}>
        <div className={"column"}>
          {videos["column-1"] &&
            videos["column-1"].map((i, index) => (
              <Video
                key={`column-1-video-${index}`}
                videoData={i}
                preload={index === 0 ? "auto" : "metadata"}
                windowSize={windowSize}
              />
            ))}
        </div>
        <div className={"column"}>
          {videos["column-2"] &&
            videos["column-2"].map((i, index) => (
              <Video
                key={`column-2-video-${index}`}
                videoData={i}
                preload={index === 0 ? "auto" : "metadata"}
                windowSize={windowSize}
              />
            ))}
        </div>
        <div className={"column"}>
          {videos["column-3"] &&
            videos["column-3"].map((i, index) => (
              <Video
                key={`column-3-video-${index}`}
                videoData={i}
                preload={index === 0 ? "auto" : "metadata"}
                windowSize={windowSize}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default CreativeVideo;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Div100vh from "react-div-100vh";

// import Landing from './components/pages/Landing'
import Dock from "./components/Dock";
import Layout from "./components/Layout";
import Photography from "./components/pages/Photography";
import Videography from "./components/pages/Videography";
import CreativeVideo from "./components/pages/Videography/CreativeVideo";
import EcommerceVideo from "./components/pages/Videography/EcommerceVideo";
import BusinessCard from "./components/pages/BusinessCard";
import Contact from "./components/pages/Contact";

import MosaicGrid from "./components/pages/MosaicGrid";

import { FirebaseProvider } from "./utils/FirebaseContext";
import { useFirebase } from "./utils/FirebaseContext";
import DigiOp from "./components/pages/DigiOp";
import Admin from "./components/pages/Admin";

function Preloader() {
  const firebase = useFirebase();
  useEffect(() => {
    firebase
      .getDoc(firebase.doc(firebase.db, "website-data", "videos"))
      .then((docSnap) => {
        if (docSnap.exists()) {
          docSnap.data()["column-1"].forEach((vid) => {
            const video = document.createElement("video");
            video.src = vid.sources[0].url;
            video.poster = vid.thumbnailURL;
            const image = new Image();
            image.src = vid.thumbnailURL;
          });
          docSnap.data()["column-2"].forEach((vid) => {
            const video = document.createElement("video");
            video.src = vid.sources[0].url;
            video.poster = vid.thumbnailURL;
            const image = new Image();
            image.src = vid.thumbnailURL;
          });
          docSnap.data()["column-3"].forEach((vid) => {
            const video = document.createElement("video");
            video.src = vid.sources[0].url;
            video.poster = vid.thumbnailURL;
            const image = new Image();
            image.src = vid.thumbnailURL;
          });
        } else {
          console.log("No such document!");
        }
      });
    firebase
      .getDoc(firebase.doc(firebase.db, "website-data", "ecommerceVideo"))
      .then((docSnap) => {
        if (docSnap.exists()) {
          docSnap.data()["column-1"].forEach((vid) => {
            const video = document.createElement("video");
            video.src = vid.sources[0].url;
            video.poster = vid.thumbnailURL;
            const image = new Image();
            image.src = vid.thumbnailURL;
          });
          docSnap.data()["column-2"].forEach((vid) => {
            const video = document.createElement("video");
            video.src = vid.sources[0].url;
            video.poster = vid.thumbnailURL;
            const image = new Image();
            image.src = vid.thumbnailURL;
          });
          docSnap.data()["column-3"].forEach((vid) => {
            const video = document.createElement("video");
            video.src = vid.sources[0].url;
            video.poster = vid.thumbnailURL;
            const image = new Image();
            image.src = vid.thumbnailURL;
          });
        } else {
          console.log("No such document!");
        }
      });
  }, [firebase]);

  return null;
}

function App() {
  // useEffect(() => {
  //   document.addEventListener("readystatechange", function (event) {
  //     console.log(event.target.readyState); // check for more states
  //     if (event.target.readyState === "complete") {
  //       // alert("Everything loaded now including images, scripts and styles.");
  //     }
  //   });
  // }, []);

  return (
    <FirebaseProvider>
      <Div100vh>
        {/* <Preloader /> */}
        <Router>
          <Routes>
            <Route
              path="/"
              exact
              element={
                <Layout>
                  {/* <MosaicGrid /> */}
                  <Videography>
                    <CreativeVideo />
                  </Videography>
                </Layout>
              }
            />
            <Route
              path="/photography"
              exact
              element={
                <Layout>
                <Photography />
                 </Layout>
              }
            />
            <Route
              path="/photo"
              exact
              element={
                <Layout>
                  <Photography />
                </Layout>
              }
            />
            <Route
              path="/videography"
              exact
              element={
                <Layout>
                  <Videography>
                    <CreativeVideo />
                  </Videography>
                </Layout>
              }
            />
            <Route
              path="/video"
              exact
              element={
                <Layout>
                  <Videography>
                    <CreativeVideo />
                  </Videography>
                </Layout>
              }
            />
            <Route
              path="/videography/creative"
              exact
              element={
                <Layout>
                  <Videography>
                    <CreativeVideo />
                  </Videography>
                </Layout>
              }
            />
            <Route
              path="/video/creative"
              exact
              element={
                <Layout>
                  <Videography>
                    <CreativeVideo />
                  </Videography>
                </Layout>
              }
            />
            <Route
              path="/videography/ecommerce"
              exact
              element={
                <Layout>
                  <Videography>
                    <EcommerceVideo />
                  </Videography>
                </Layout>
              }
            />
            <Route
              path="/video/ecommerce"
              exact
              element={
                <Layout>
                  <Videography>
                    <EcommerceVideo />
                  </Videography>
                </Layout>
              }
            />
            <Route path="/businesscard" exact element={<BusinessCard />} />
            <Route
              path="/contact"
              exact
              element={
                <Layout>
                  <Contact />
                </Layout>
              }
            />
            <Route
              path="/admin"
              exact
              element={
                <Layout>
                  <Admin />
                </Layout>
              }
            />
            <Route
              path="/digiop"
              exact
              element={
                // <Layout>
                //   <DigiOp />
                // </Layout>
                <></>
              }
            />
          </Routes>
        </Router>
      </Div100vh>
    </FirebaseProvider>
  );
}

export default App;

import React, { useEffect } from 'react'
import logoAlt from '../../logo512Alt.png';

import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';


const BusinessCard = () => {
    const containerStyle = {
        backgroundColor: '#121212',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',

    }
    const logoStyle = {
        width: 150,
        marginTop: 50,
    }
    const linksContainer = {
        marginTop: 30,
    }

    useEffect(() => {
        const existingMetas = Array.from(document.querySelectorAll('meta[name=theme-color]'))
        existingMetas.forEach(meta => {
            meta.remove()
            meta.remove()
        })
        let lightMeta = document.createElement('meta')
        lightMeta.name = 'theme-color'
        lightMeta.content = `#121212`
        lightMeta.media = '(prefers-color-scheme: light)'
        document.querySelector('head').append(lightMeta)
    }, [])

    return (
        <div style={containerStyle}>
            <div>
                <img style={logoStyle} src={logoAlt} alt="Logo" />
            </div>
            <div style={linksContainer}>
                <Link label={"alexmorrisseysmith.com"} Icon={<LanguageIcon style={{ marginRight: 20 }} />} link={"https://alexmorrisseysmith.com"} />
                <Link label={"info@alexmorrisseysmith.com"} Icon={<MailOutlineIcon style={{ marginRight: 20 }} />} link={"mailto:info@alexmorrisseysmith.com"} />
                <Link label={"0481 308 553"} Icon={<PhoneIphoneIcon style={{ marginRight: 20 }} />} link={"tel:0481308553"} />
                <Link label={"@alexmorrisseysmith"} Icon={<InstagramIcon style={{ marginRight: 20 }} />} link={"https://www.instagram.com/alexmorrisseysmith"} />
                <Link label={"Alex Morrissey-Smith"} Icon={<FacebookIcon style={{ marginRight: 20 }} />} link={"https://www.facebook.com/AlexMorrisseySmithPhotography"} />
            </div>
        </div>
    )
}

export default BusinessCard

const Link = ({ label, Icon, link }) => {
    const style = {
        width: '85vw',
        maxWidth: '500px',
        backgroundColor: '#f7f7f7',
        height: 50,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
    }
    const innerStyle = {
        marginLeft: 18,
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.1em',
    }
    return (
        <div style={style} className={'businessCardLink'} onClick={() => { window.open(link, '_blank') }}>
            <div style={innerStyle}>
                {Icon}{label}
            </div>
        </div>
    )
}
import React, { useState, useEffect, cloneElement } from 'react'
import { Link, useLocation } from 'react-router-dom';

import './style.css'

// import Loading from './Loading'
// import CreativeVideo from './Videography/CreativeVideo'
// import EcommerceVideo from './Videography/EcommerceVideo'

// import { useFirebase } from '../../utils/FirebaseContext'


const pages = [
    {
        title: 'Creative',
        path: '/videography/creative',
    },
    {
        title: 'Ecommerce',
        path: '/videography/ecommerce',
    },
]

const Videography = ({ children }) => {
    const windowSize = useWindowSize();
    const location = useLocation()

    const [linksMargin, setLinksMargin] = useState(-15)

    const linkStyle = {
        textDecoration: 'none',
        margin: 10,
        fontSize: 15
    }
    const linksContainer = {
        wdith: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: linksMargin,
        marginBottom: -2,
    }

    useEffect(() => {
        if (windowSize.width < 600) {
            setLinksMargin(-12)
        }
    }, [windowSize])

    return (
        <>
            <div style={linksContainer}>
                {pages.map((page, index) => (
                    <Link key={`page-${index}`}
                        style={{
                            ...linkStyle,
                            color: shouldBeSelected(location, page)
                                ? '#0d0d0d'
                                : '#8c8c8c'
                        }}
                        to={page.path}>{page.title}</Link>
                ))}
            </div>
            {cloneElement(children, { windowSize })}
            {/* {children} */}
        </>
    )
}
export default Videography

function shouldBeSelected(location, page) {
    let result = false
    if (location.pathname.includes(page.path)) {
        result = true
    }
    if (location.pathname === '/videography' && page.path.includes('creative')) {
        result = true
    }
    return result
}

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}
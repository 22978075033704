import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import logo from "../logo512.png";
import logoAlt from "../logo512Alt.png";

import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Drawer } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const pages = [
  {
    title: "Videography",
    path: "/videography",
  },
  {
    title: "Photography",
    path: "/photography",
  },
  // {
  //   title: "Digi Op",
  //   path: "/digiop",
  // },
  // {
  //     title: 'About',
  //     path: '/about',
  // },
  {
    title: "Contact",
    path: "/contact",
  },
];

const Layout = ({ children }) => {
  const windowSize = useWindowSize();
  const [headerHeight, setHeaderHeight] = useState(200);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingTop, setLoadingTop] = useState(200);

  useEffect(() => {
    if (location.pathname.includes("photo")) {
      setIsLoading(true);
      setLoadingTop(headerHeight);
    }
    if (location.pathname.includes("video")) {
      setIsLoading(true);
      setLoadingTop(headerHeight + 20);
    }
  }, [location, headerHeight]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [isLoading]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (windowSize.width < 800) {
      setHeaderHeight(100);
    } else {
      setHeaderHeight(200);
    }
  }, [windowSize]);

  const containerStyle = {
    height: "100%",
  };
  const headerStyle = {
    // wdith: '100vw',
    height: `${headerHeight}px`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // flexDirection: 'row',
    maxWidth: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  };

  const linkStyle = {
    textDecoration: "none",
    margin: 10,
  };

  return (
    <div style={containerStyle}>
      <div style={{}}>
        <div style={headerStyle}>
          <img
            className="logo"
            src={windowSize.width > 800 ? logo : logoAlt}
            style={{
              width: windowSize.width > 800 ? 284 : 69,
              height: windowSize.width > 800 ? 120 : 90,
            }}
            alt="Logo"
          />

          <div style={{ display: windowSize.width > 800 ? "none" : "block" }}>
            <IconButton
              onClick={() => {
                setDrawerOpen(true);
              }}
            >
              <MenuIcon />
            </IconButton>
          </div>

          <div style={{ display: windowSize.width > 800 ? "block" : "none" }}>
            {pages.map((page, index) => (
              <Link
                key={`page-${index}`}
                style={{
                  ...linkStyle,
                  color: page.path === "/photography" ? "#0d0d0d" : "#8c8c8c",
                }}
                to={page.path}
              >
                {page.title}
              </Link>
            ))}
          </div>
        </div>
      </div>

      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <div style={{ width: 180 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            {pages.map((page, index) => (
              <Link
                key={`page-${index}`}
                onClick={() => {
                  setDrawerOpen(false);
                }}
                style={{
                  ...linkStyle,
                  color:
                    location.pathname.includes(page.path) ||
                    (location.pathname === "/" && page.path === "/videography")
                      ? "#0d0d0d"
                      : "#8c8c8c",
                }}
                to={page.path}
              >
                {page.title}
              </Link>
            ))}
          </div>
        </div>
      </Drawer>

      <div style={{ height: `calc(100% - ${headerHeight + 25}px)` }}>
        {/* <div> */}
        <div
          style={{
            display: isLoading ? "flex" : "none",
            //   background: "red",
            background: "#f2f2f2",
            position: "absolute",
            top: loadingTop,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2000,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
        {children}
      </div>
      {/* </div> */}
    </div>
  );
};

export default Layout;

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

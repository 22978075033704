import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { useFirebase } from "../../utils/FirebaseContext";

const Contact = () => {
  const firebase = useFirebase();

  const containerStyle = {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  };
  const innerContainer = {
    width: "clamp(400px, 40vw, 700px)",
    margin: 10,
    marginTop: 30,
  };
  const innerContainer2 = {
    width: "clamp(400px, 40vw, 700px)",
    margin: 10,
    marginTop: 50,
    marginBottom: 80,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  };
  const formStyle = {
    width: 400,
    margin: 10,
  };

  const [submitButtonContent, setSubmitButtonContent] = useState("SUBMIT");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmittedSuccess, setFormSubmittedSuccess] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);

  const submitForm = async () => {
    if (formSubmitted) return;
    let error = false;
    if (!name) {
      setNameError(true);
      error = true;
    } else {
      setNameError(false);
    }
    if (!email) {
      setEmailError(true);
      error = true;
    } else {
      setEmailError(false);
    }
    if (!message) {
      setMessageError(true);
      error = true;
    } else {
      setMessageError(false);
    }
    if (error) return;
    setFormSubmitted(true);
    setSubmitButtonContent(<CircularProgress size={28} />);
    await firebase.submitContactForm({
      name: name,
      email: email,
      message: message,
    });
    setFormSubmittedSuccess(true);
    setName("");
    setEmail("");
    setMessage("");
  };
  return (
    <div style={containerStyle}>
      <div style={innerContainer}>
        <h2 style={{ fontWeight: 100 }}>CONTACT</h2>
        <p>
          If you would like to contact me, please fill out the form with any
          details you think I might need!
        </p>
        <p>Alternatively you can see my contact info below:</p>
        <p style={{ marginTop: 8, marginBottom: 8 }}>
          <span>Email: </span>
          <a href="mailto:info@alexmorrisseysmith.com">
            info@alexmorrisseysmith.com
          </a>
        </p>
        <p style={{ marginTop: 8, marginBottom: 8 }}>
          <span>Phone: </span> <a href="tel:0481308553">0481308553</a>
        </p>
        <p style={{ marginTop: 8, marginBottom: 8 }}>
          <span>Instagram: </span>
          <a
            href="https://www.instagram.com/alexmorrisseysmith/"
            target="_blank"
            rel="noreferrer"
          >
            @alexmorrisseysmith
          </a>
        </p>
      </div>
      <div style={innerContainer2}>
        <TextField
          label="Name"
          value={name}
          error={nameError}
          onChange={(e) => {
            setName(e.target.value);
          }}
          variant="outlined"
          style={formStyle}
        />
        <TextField
          label="Email"
          value={email}
          error={emailError}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          variant="outlined"
          style={formStyle}
          type="email"
        />
        <TextField
          label="Message"
          value={message}
          error={messageError}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          variant="outlined"
          style={formStyle}
          multiline
          rows={8}
        />
        <Button
          variant="outlined"
          onClick={submitForm}
          style={{ ...formStyle, height: 45 }}
        >
          {formSubmittedSuccess ? (
            <CheckCircleOutlineIcon fontSize="large" />
          ) : (
            submitButtonContent
          )}
        </Button>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import "./MosaicGrid.css"; // Import the CSS for the grid

const mediaItems = [
  {
    type: "photo",
    url: "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/images%2Fimage1%2Fjpg%2Fimage1_1400w.jpg?alt=media&token=a73a8813-c890-43f3-8916-f82465f34d48",
    name: "Image 1",
    width: 1400 / 2,
    height: 2100 / 2,
  },
  {
    type: "video",
    thumbnailURL:
      "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FMarrowbone_Hero_16x9%2Fthumb%2FMarrowbone_Hero_16x9.jpg?alt=media&token=9b061f68-e273-4cf0-80a2-dd055bd1ac70",
    name: "Video 1",
    width: 500 / 2,
    height: 281 / 2,
  },
  ...Array(10)
    .fill()
    .flatMap(() => [
      {
        type: "photo",
        url: "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/images%2Fimage1%2Fjpg%2Fimage1_1400w.jpg?alt=media&token=a73a8813-c890-43f3-8916-f82465f34d48",
        name: "Image 1",
        width: 1400 / 2,
        height: 2100 / 2,
      },
      {
        type: "video",
        thumbnailURL:
          "https://firebasestorage.googleapis.com/v0/b/alex-morrissey-smith-website.appspot.com/o/videos%2FMarrowbone_Hero_16x9%2Fthumb%2FMarrowbone_Hero_16x9.jpg?alt=media&token=9b061f68-e273-4cf0-80a2-dd055bd1ac70",
        name: "Video 1",
        width: 500 / 2,
        height: 281 / 2,
      },
    ]),
];

const MosaicGrid = () => {
  return (
    <div className="mosaic-grid">
      {mediaItems.map((item, index) => (
        <div
          key={index}
          className="mosaic-item"
          style={{
            gridColumnEnd: `span ${Math.ceil(item.width / 300)}`,
            gridRowEnd: `span ${Math.ceil(item.height / 300)}`,
          }}
        >
          {item.type === "photo" ? (
            <img src={item.url} alt={item.name} />
          ) : (
            <img src={item.thumbnailURL} alt={item.name} />
          )}
        </div>
      ))}
    </div>
  );
};

export default MosaicGrid;

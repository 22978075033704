import React, { useState, useEffect, useRef } from "react";
import { useInViewEffect } from "react-hook-inview";

const Video = ({ videoData, preload, windowSize }) => {
  const [imgVisible, setImgVisible] = useState(true);
  const [shouldAutoPlay, setShouldAutoPlay] = useState(false);
  useEffect(() => {
    if (windowSize) {
      if (windowSize.width < 600) {
        setShouldAutoPlay(true);
      } else {
        setShouldAutoPlay(false);
      }
    }
  }, [windowSize]);

  const vidRef = useRef(null);
  const ref = useInViewEffect(
    ([entry], observer) => {
      // console.log(entry)
      if (entry.isIntersecting) {
        // observer.unobserve(entry.target)
        // console.log(videoData.thumbnailURL)
        vidRef.current.play();
        setImgVisible(false);
      } else {
        vidRef.current.pause();
        vidRef.current.currentTime = 0;
        setImgVisible(true);
      }
      // console.log(entry.isIntersecting)
    },
    { threshold: 1 }
  );
  const hover = (e) => {
    vidRef.current.play();
    const parent = e.target.parentNode;
    const img = Array.from(parent.getElementsByTagName("img"));
    const video = Array.from(parent.getElementsByTagName("video"));
    const svg = Array.from(parent.getElementsByTagName("svg"));
    if (img.length === 0) {
      return;
    }
    if (video.length === 0) {
      return;
    }
    if (svg.length === 0) {
      return;
    }
    setImgVisible(false);
  };
  const hoverExit = (e) => {
    vidRef.current.pause();
    vidRef.current.currentTime = 0;
    const parent = e.target.parentNode;
    const img = Array.from(parent.getElementsByTagName("img"));
    const video = Array.from(parent.getElementsByTagName("video"));
    const svg = Array.from(parent.getElementsByTagName("svg"));
    if (img.length === 0) {
      return;
    }
    if (video.length === 0) {
      return;
    }
    if (svg.length === 0) {
      return;
    }
    setImgVisible(true);
  };
  return (
    <div
      ref={shouldAutoPlay ? ref : null}
      className="hoverDiv"
      onMouseEnter={hover}
      onMouseLeave={hoverExit}
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 60 60"
        style={{ opacity: imgVisible ? 0.6 : 0 }}
      >
        <g>
          <path
            fill="#ffffff"
            d="M45.563,29.174l-22-15c-0.307-0.208-0.703-0.231-1.031-0.058C22.205,14.289,22,14.629,22,15v30
        c0,0.371,0.205,0.711,0.533,0.884C22.679,45.962,22.84,46,23,46c0.197,0,0.394-0.059,0.563-0.174l22-15
        C45.836,30.64,46,30.331,46,30S45.836,29.36,45.563,29.174z M24,43.107V16.893L43.225,30L24,43.107z"
          ></path>
          <path
            fill="#ffffff"
            d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30
        S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"
          ></path>
        </g>
      </svg>
      <img
        className="hoverImg"
        src={videoData.thumbnailURL}
        style={{ opacity: imgVisible ? 1 : 0,  }}
        alt="test"
      />
      <video
        ref={vidRef}
        poster={videoData.thumbnailURL}
        className="hoverVid"
        style={{ maxWidth: 500 }}
        loop={true}
        muted={true}
        playsInline={true}
        preload={"auto"}
      >
        {/* <source type="video/mp4" src={`${video}#t=0.3`} /> */}
        {videoData.sources.map((source, index) => (
          <source
            key={`${source.url}-${index}`}
            type={source.type}
            src={`${source.url}#t=0.3`}
          />
        ))}
      </video>
    </div>
  );
};

export default Video;

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { useFirebase } from "../../utils/FirebaseContext";

const Admin = () => {
  const firebase = useFirebase();

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "85%",
  };
  return (
    <div style={containerStyle}>
      {firebase.user ? <AdminContainer /> : <LoginContainer />}
    </div>
  );
};

export default Admin;

const AdminContainer = () => {
  return <div>admin</div>;
};

const LoginContainer = () => {
  const firebase = useFirebase();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [email, setEmail] = useState("alexmorrisseysith@gmail.com");
  // const [password, setPassword] = useState("1606252762");

  const login = () => {
    firebase.signInWithEmailAndPassword(firebase.auth, email, password);
  };

  const textFieldStyle = {
    marginBottom: 15,
    width: 220,
  };
  return (
    <>
      <p>Admin Login</p>
      <TextField
        label="Email"
        value={email}
        // error={nameError}
        size="small"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        variant="outlined"
        style={textFieldStyle}
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        size="small"
        // error={nameError}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        variant="outlined"
        style={textFieldStyle}
      />
      <Button variant="contained" onClick={login}>
        Log In
      </Button>
    </>
  );
};

import React, { useState, useEffect, useRef } from 'react'

import { useFirebase } from '../../../utils/FirebaseContext'

import Video from '../components/Video'

const EcommerceVideo = ({ windowSize }) => {
    const containerRef = useRef(null);

    const firebase = useFirebase()
    const [videos, setVideos] = useState({})

    useEffect(() => {
        firebase.getDoc(firebase.doc(firebase.db, "website-data", "ecommerceVideo")).then(docSnap => {
            if (docSnap.exists()) {
                setVideos(docSnap.data())
            } else {
                console.log("No such document!");
            }
        })
    }, [firebase])

    const containerStyle = {
        width: '100vw',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
    }
    return (
        <div style={containerStyle} ref={containerRef}>
            <div className={'row'}>
                <div className={'column'}>
                    {videos['column-1'] && videos['column-1'].map((i, index) => (
                        <Video key={`column-1-video-${index}`} videoData={i} preload={index === 0 ? 'auto' : 'metadata'} windowSize={windowSize} />
                    ))}
                </div>
                <div className={'column'}>
                    {videos['column-2'] && videos['column-2'].map((i, index) => (
                        <Video key={`column-2-video-${index}`} videoData={i} preload={index === 0 ? 'auto' : 'metadata'} windowSize={windowSize} />
                    ))}
                </div>
                <div className={'column'}>
                    {videos['column-3'] && videos['column-3'].map((i, index) => (
                        <Video key={`column-3-video-${index}`} videoData={i} preload={index === 0 ? 'auto' : 'metadata'} windowSize={windowSize} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default EcommerceVideo
import React, { useRef, useState, useEffect, useCallback } from "react";

import {
  motion,
  useMotionValue,
  useSpring,
  useTransform,
  useAnimate,
} from "framer-motion";

import { createGlobalStyle } from "styled-components";
import { Link, useLocation } from "react-router-dom";
// import { useHistory, useLocation } from "react-router-dom";

// import { useAtom } from "jotai";
// import {
//   themeAtom,
//   isAdminAtom,
//   isMobileBrowserAtom,
//   exportDatesModalOpenAtom,
//   isDockHiddenAtom,
// } from "./-Atoms";

// import { subscribe, unsubscribe } from "./GlobalFunctions/customEvent";

// import { useMediaQuery } from "@mui/material";

const GlobalStyles = createGlobalStyle`
.toolTip {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.visible {
  opacity: 1;
}
.iconSizeSmall {
  font-size: 25px;
  transition: font-size 0.2s ease;
}
.iconSizeLarge {
  font-size: 40px;
}
`;

const Dock = ({ restricted }) => {
  // const [theme] = useAtom(themeAtom);
  const isAdmin = false;
  const isMobileBrowser = false;
  const isDockHidden = false;
  const setIsDockHidden = () => {};
  // const [isAdmin] = useAtom(isAdminAtom);
  // const [isMobileBrowser] = useAtom(isMobileBrowserAtom);
  // const [isDockHidden, setIsDockHidden] = useAtom(isDockHiddenAtom);
  // const [, setExportDatesModalOpen] = useAtom(exportDatesModalOpenAtom);
  const [scopeHide, animateHide] = useAnimate();
  const [scopeShow, animateShow] = useAnimate();

  // const location = useLocation();

  const pages = [
    {
      name: "Videography",
      route: "/videography",
      icon: "videocam",
      admin: false,
    },
    {
      name: "Photography",
      route: "/photography",
      icon: "photo_camera",
      admin: false,
    },
    {
      name: "Contact",
      route: "/contact",
      icon: "contact_page",
      admin: false,
    },
  ];

  // const hideDock = useCallback(
  //   (manualHide = false) => {
  //     animateHide(
  //       scopeHide.current,
  //       { y: 150 },
  //       { type: "spring", damping: 13, stiffness: 200 }
  //     );
  //     animateShow(
  //       scopeShow.current,
  //       { y: 0 },
  //       { type: "spring", damping: 13, stiffness: 200 }
  //     );
  //     if (manualHide === true) {
  //       setIsDockHidden(true);
  //     }
  //   },
  //   [animateHide, animateShow, scopeHide, scopeShow, setIsDockHidden]
  // );

  // const showDock = useCallback(() => {
  //   animateHide(
  //     scopeHide.current,
  //     { y: 0 },
  //     { type: "spring", damping: 13, stiffness: 200 }
  //   );
  //   animateShow(
  //     scopeShow.current,
  //     { y: 150 },
  //     { type: "spring", damping: 13, stiffness: 200 }
  //   );
  //   setIsDockHidden(false);
  // }, [animateHide, animateShow, scopeHide, scopeShow, setIsDockHidden]);

  // const buttons = [
  //   {
  //     name: `Export Dates`,
  //     function: () => {
  //       // setExportDatesModalOpen(true);
  //       // hideDock();
  //     },
  //     icon: "ios_share",
  //   },
  // ];

  // useEffect(() => {
  //   subscribe("showDock", showDock);
  //   subscribe("hideDock", hideDock);

  //   return () => {
  //     unsubscribe("showDock", showDock);
  //     unsubscribe("hideDock", hideDock);
  //   };
  // }, [showDock, hideDock]);

  // const small = useMediaQuery("@media (max-width:400px)");
  // const mid = useMediaQuery("@media (max-width:600px)");
  // const large = useMediaQuery("@media (min-width:600px)");

  // console.log(small);
  // console.log(mid);
  // console.log(large);

  let mouseX = useMotionValue(Infinity);
  return (
    <>
      <GlobalStyles />

      <div
        style={{
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: 10,
          zIndex: 999999,
          pointerEvents: isDockHidden ? "none" : "all",
        }}
      >
        <motion.div
          ref={scopeHide}
          onMouseMove={(e) => mouseX.set(e.pageX)}
          onMouseLeave={() => mouseX.set(Infinity)}
          className={`mx-auto flex ${
            isMobileBrowser ? "h-14 gap-2" : "h-16 gap-4"
          } items-end px-3.5 pb-2.5`}
          //
          style={{
            // backgroundColor: "rgba(255, 255, 255, 0.1)",
            backgroundColor: "rgba(10, 10, 10, 0.2)",
            backdropFilter: "blur(8px)",
            WebkitBackdropFilter: "blur(8px)",
            borderRadius: 50,
          }}
        >
          {pages &&
            pages.map((page, index) =>
              shouldReturnPage(page, isAdmin, restricted) ? (
                <AppIcon mouseX={mouseX} key={index} page={page} />
              ) : null
            )}
        </motion.div>
      </div>
    </>
  );
};
export default Dock;

function shouldReturnPage(page, isAdmin, restricted) {
  if (isAdmin) return true;
  if (restricted) {
    if (page.restricted) return true;
  } else {
    if (!page.admin) return true;
  }
  return false;
}

function AppIcon({ mouseX, page }) {
  const isMobileBrowser = false;
  // const [isMobileBrowser] = useAtom(isMobileBrowserAtom);

  const location = useLocation();
  // const history = useHistory();

  const [growValues, setGrowValues] = useState([45, 70, 45]);
  useEffect(() => {
    isMobileBrowser ? setGrowValues([35, 35, 35]) : setGrowValues([45, 70, 45]);
  }, [isMobileBrowser]);

  const [ref, animateIcon] = useAnimate();

  // let ref = useRef(null);

  let distance = useTransform(mouseX, (val) => {
    let bounds = ref.current?.getBoundingClientRect() ?? { x: 0, width: 0 };

    return val - bounds.x - bounds.width / 2;
  });

  let widthSync = useTransform(distance, [-150, 0, 150], growValues);
  let width = useSpring(widthSync, { mass: 0.1, stiffness: 150, damping: 12 });

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Link to={page.route}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          style={{
            position: "absolute",
            top: -52,
            backgroundColor: "rgba(30, 30, 30, 0.8)",
            color: "#e5e5e5",
            borderRadius: 8,
            padding: "4px 8px",
            whiteSpace: "nowrap",
          }}
          className={`toolTip ${isHovered ? "visible" : ""}`}
        >
          {page.name}
        </div>
        <motion.div
          ref={ref}
          initial={{ y: 0 }}
          style={{
            width,
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(40, 40, 40, 0.95)",
            containerType: "inline-size",
          }}
          className="aspect-square w-10 rounded-full"
          onClick={() => {
            animateIcon(
              ref.current,
              { y: -15 },
              { type: "spring", damping: 13, stiffness: 500 }
            );
            setTimeout(() => {
              animateIcon(
                ref.current,
                { y: -0 },
                { type: "spring", damping: 13, stiffness: 500 }
              );
            }, 200);
          }}
        >
          <span
            style={{
              fontSize: "60cqw",
              width: "60cqw",
              color: "#e5e5e5",
            }}
            class="material-icons"
          >
            {page.icon}
          </span>
        </motion.div>
        <div
          style={{
            position: "absolute",
            bottom: 3,
            backgroundColor: "#e5e5e5",
            borderRadius: 25,
            width: 3.5,
            height: 3.5,
            display: "block",
            display: shouldShowPageDot(location.pathname, page.route)
              ? "block"
              : "none",
          }}
        ></div>
      </div>
    </Link>
  );
}
function shouldShowPageDot(pathName, route) {
  if (pathName.includes(route)) return true;
  if (pathName === "/" && route.includes("videography")) return true;
  return false;
}

// function AppIconButton({ mouseX, button }) {
//   const isMobileBrowser = false;
//   // const [isMobileBrowser] = useAtom(isMobileBrowserAtom);

//   const [growValues, setGrowValues] = useState([45, 70, 45]);
//   useEffect(() => {
//     isMobileBrowser ? setGrowValues([35, 35, 35]) : setGrowValues([45, 70, 45]);
//   }, [isMobileBrowser]);

//   let ref = useRef(null);

//   let distance = useTransform(mouseX, (val) => {
//     let bounds = ref.current?.getBoundingClientRect() ?? { x: 0, width: 0 };

//     return val - bounds.x - bounds.width / 2;
//   });

//   let widthSync = useTransform(distance, [-150, 0, 150], growValues);
//   let width = useSpring(widthSync, { mass: 0.1, stiffness: 150, damping: 12 });

//   const [isHovered, setIsHovered] = useState(false);

//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };

//   return (
//     <div
//       style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={handleMouseLeave}
//     >
//       <div
//         style={{
//           position: "absolute",
//           top: -52,
//           backgroundColor: "rgba(30, 30, 30, 0.8)",
//           borderRadius: 8,
//           padding: 4,
//           whiteSpace: "nowrap",
//         }}
//         className={`toolTip ${isHovered ? "visible" : ""}`}
//       >
//         {button.name}
//       </div>
//       <motion.div
//         ref={ref}
//         style={{
//           width,
//           cursor: "pointer",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           backgroundColor: "rgba(40, 40, 40, 0.95)",
//           containerType: "inline-size",
//         }}
//         className="aspect-square w-10 rounded-full"
//         onClick={() => button.function()}
//       >
//         <span
//           style={{
//             fontSize: "60cqw",
//             width: "60cqw",
//             color: "#e5e5e5",
//           }}
//           class="material-icons"
//         >
//           {button.icon}
//         </span>
//       </motion.div>
//     </div>
//   );
// }

import React, { useContext, createContext } from "react";

import { initializeApp } from "firebase/app";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getAuth,
  signInWithEmailAndPassword,
  // sendPasswordResetEmail,
  signOut,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import {
  getFirestore,
  query,
  collection,
  doc,
  onSnapshot,
  getDoc,
  setDoc,
} from "firebase/firestore";
// import 'firebase/firestore';
import { useAuthState } from "react-firebase-hooks/auth";

const FirebaseContext = createContext();

export function useFirebase() {
  return useContext(FirebaseContext);
}

export function FirebaseProvider({ children }) {
  const firebaseConfig = {
    apiKey: "AIzaSyBpErTtWKNGBLdCC4ywnWDdR8FeaPvZTlg",
    authDomain: "alex-morrissey-smith-website.firebaseapp.com",
    projectId: "alex-morrissey-smith-website",
    storageBucket: "alex-morrissey-smith-website.appspot.com",
    messagingSenderId: "302228694329",
    appId: "1:302228694329:web:be88eeb5e10597fbf8b4e8",
    measurementId: "G-6XKMSXS3KJ",
  };

  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);
  // const listRef = ref(storage, '/images/image1');

  const db = getFirestore(app);
  const auth = getAuth();
  setPersistence(auth, browserSessionPersistence);
  const functions = getFunctions(app);

  const [user] = useAuthState(auth);

  const submitContactForm = httpsCallable(functions, "submitContactForm");

  return (
    <FirebaseContext.Provider
      value={{
        app,
        storage,
        ref,
        listAll,
        getDownloadURL,
        db,
        query,
        collection,
        doc,
        onSnapshot,
        getDoc,
        setDoc,
        auth,
        user,
        signInWithEmailAndPassword,
        // sendPasswordResetEmail,
        signOut,
        // setPersistence,
        functions,
        submitContactForm,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
}

// import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";
// import { getFirestore, collection, onSnapshot, query, where, getDocs, } from "firebase/firestore";
